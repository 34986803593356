import { AuditOrderTypes, AuditStatuses } from "../misc/constants";
import { get } from "./get";

export const getPreviousAudit = ({ specialAudits = [] }) => {
  if (!specialAudits?.length) return "-";
  const specialAudit = specialAudits.reduce((prev, next) => {
    const prevTime = new Date(prev?.auditId?.createdAt).getTime();
    const nextTime = new Date(next?.auditId?.createdAt).getTime();
    if (nextTime < prevTime) {
      prev = next;
    }
    return prev;
  }, specialAudits[0]);
  return specialAudit?.batchId ? specialAudit.batchId?.name : "-";
};

export const isAuditAdditional = ({ specialAudits = [] }) => {
  if (!specialAudits?.length) return false;
  const additional = specialAudits.find(
    ({ batchId = null, auditId = null }) =>
      !batchId && auditId?.executionOrder === AuditOrderTypes.additional
  );
  return !!additional;
};

export const calculateCaseScore = (caseEvaluation) => {
  const values = Object.values(caseEvaluation);
  const scoreValues = values.filter((value) => value !== null && value !== -1);
  if (!scoreValues.length) return 0;
  const calculatedScore = scoreValues.reduce((sum, value) => (sum += value), 0);
  const maxScore = scoreValues.length * 2;
  return parseFloat(((calculatedScore / maxScore) * 100).toFixed(2));
};

export const caluclateGeneralAuditScore = (caseEvaluation) => {
  const items = Object.values(caseEvaluation);

  const scoreValues = items.filter((itm) => itm && itm.value !== null && itm.value !== -1).map(itm => itm.value);
  if (!scoreValues.length) return 0;

  const calculatedScore = scoreValues.reduce((sum, value) => (sum += value), 0);
  const maxScore = scoreValues.length;

  return parseFloat(((calculatedScore / maxScore) * 100).toFixed(2));
};

export const getScoreRowColor = (score) => {
  if (!score) return "";
  if (score < 75) return "audit-score-errored";
  if (score < 90) return "audit-score-warning";
  return "";
};

export const getAuditScore = ({ audit }) => {
  const isComplete = get(audit, "status", null) === AuditStatuses.complete;
  return get(audit, "score", "-", isComplete)
};

export const mapValueToText = (value) => {
  switch (value) {
    case 1:
      return "Yes";
    case 0:
      return "No";
    case -1:
      return "N/A";
    default:
      return "-";
  };
}
